import * as React from "react"

import Layout from "../../components/layout"
import Seo from "../../components/seo"
import { useLocation } from "@reach/router"

import ContactMe from "../../components/ContactForms/ContactForm"
import { deDE } from "../../lang/de-DE"

export default function Home() {
  const location = useLocation()

  const country = "CH"
  const lang = "de-CH"
  const txt = deDE.ContactMe

  return (
    <Layout location={country} path={location.pathname} language={lang}>
      <Seo
        title="DoryGo - Anfragen"
        description="Ich möchte beratet werden"
        lang={lang}
      />

    <ContactMe localizedText={txt} />

    </Layout>
  )
}
